import BaseService from "./base.service";
import http from "../http-common";
import { getResponseData, customizeError, checkEnterpriseId } from "../helpers/_functions";

class EmploiFormationService extends BaseService {
  constructor() {
    super("emploi-formation")
  }

  getByEmployer(employeId, params = {}) {
    return http.get(`${this.path}/getByEmployer/${employeId}`, { params })
              .then(res => getResponseData(res))
              .catch((err) => Promise.reject(customizeError(err)));
  }

  upsertEmploiFormation(body, params = {}) {
    return http.post(`${this.path}/upsert`, body, { params })
            .then(res => getResponseData(res))
            .catch((err) => Promise.reject(customizeError(err)));
  }

  deleteEmploiFormation(id, params = {}) {
    return http.delete(`${this.path}/deleteById/${id}`, { params })
              .then(res => getResponseData(res))
              .catch((err) => Promise.reject(customizeError(err)));
  }

  upsertOption(body, params = {}) {
    return http.post(`${this.path}/option/upsert`, body, { params })
              .then(res => getResponseData(res))
              .catch((err) => Promise.reject(customizeError(err)));
  }

  deleteOption(id, params = {}) {
    return http.delete(`${this.path}/option/deleteById/${id}`, { params })
              .then(res => getResponseData(res))
              .catch((err) => Promise.reject(customizeError(err)));
  }

  getOptionsByEnterprise(enterpriseId = "", params = {}) { 
    return http.get(`${this.path}/option/getByEnterprise/${checkEnterpriseId(enterpriseId)}`, { params })
              .then(res => getResponseData(res))
              .catch((err) => Promise.reject(customizeError(err)));
  }

  validateListImport(body, params = {}) {
    params.enterpriseId = checkEnterpriseId();
    return http.post(`${this.path}/validate-import`, body, { params })
              .then(res => getResponseData(res))
              .catch((err) => Promise.reject(customizeError(err)));
  }

  saveImported(body, params = {}) {
    params.Enterprise = checkEnterpriseId();
    return http.post(`${this.path}/save-imported`, body, { params })
              .then(res => getResponseData(res))
              .catch((err) => Promise.reject(customizeError(err)));
  }

  downloadGabarit() {
    return http.get(`${this.path}/downloadGabarit`, {
      responseType: "blob"
    });
  }

  exportResultImport(body) {
    return http.post(`${this.path}/export-result-import`, body)
              .then(res => getResponseData(res))
              .catch((err) => Promise.reject(customizeError(err)));
  }

  download(theFileName) {
    return http.get(`${this.path}/download/${theFileName}`, {
      responseType: "blob"
    });
  }
  
  getFormationsByEnterprise(id, params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return http.get(`${this.path}/lists/${id}`, { params })
              .then(res => getResponseData(res))
              .catch((err) => Promise.reject(customizeError(err)));
  }
}

export default new EmploiFormationService();