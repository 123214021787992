import { getResponseQuery } from "../../helpers/_functions";
import http from "../../http-common";
import BaseService from "../base.service";

class EvaluationAlert extends BaseService {
  constructor() {
    super("evaluation-alert");
  }

  getByAssignedEmployee(assignedEmployee, params = {}) {
    return getResponseQuery(
      http.get(`${this.path}/get-by-assigned-employee/${assignedEmployee}`, {
        params,
      }),
    );
  }
}

export default new EvaluationAlert();
