import { makeActionHandler } from "./_reducerUtils";

export const evaluationReducer = {
  initialState: {
    evaluationResult: {
      list: [],
    },
    evaluationModel: {
      list: [],
      isLoaded: false,
      selected: null,
    },
    evaluationCriteriaGroup: {
      list: [],
      isLoaded: false,
    },
    evaluationQuestion: {
      list: [],
      isLoaded: false,
    },
    resumePerformance: {
      list: [],
    },
    resumeCompetence: {
      list: [],
    },
    evaluationResultDetails: {
      /** @type {Object} */
      details: {},
      graphData: [],
      notations: [],
      isLoading: false,
      isLoaded: false,
      isChangingYear: false,
      /** @type {number | null} */
      year: null,
    },
    evaluationHistory: {
      list: [],
      isLoading: false,
      isLoaded: false,
    },
    evaluationAlert: {
      competenceAlert: null,
      performanceAlert: null,
      isLoaded: false,
    },
    eligibleEvaluator: {
      list: [],
      isLoaded: false,
    },
  },
  handlers: {
    "evaluation/SET_EVALUATION_RESULT": makeActionHandler("evaluationResult"),
    "evaluation/SET_EVALUATION_MODEL": makeActionHandler("evaluationModel"),
    "evaluation/SET_ELIGIBLE_EVALUATOR": makeActionHandler("eligibleEvaluator"),
    "evaluation/SET_EVALUATION_CRITERIA_GROUP": makeActionHandler(
      "evaluationCriteriaGroup",
    ),
    "evaluation/SET_EVALUATION_QUESTION":
      makeActionHandler("evaluationQuestion"),

    "evaluation/SET_RESUME_PERFORMANCE": makeActionHandler("resumePerformance"),
    "evaluation/SET_RESUME_COMPETENCE": makeActionHandler("resumeCompetence"),
    "evaluation/SET_EVALUATION_RESULT_DETAILS": makeActionHandler(
      "evaluationResultDetails",
    ),
    "evaluation/SET_EVALUATION_HISTORY": makeActionHandler("evaluationHistory"),

    "evaluation/SET_EVALUATION_ALERT": makeActionHandler("evaluationAlert"),

    "evaluation/RESET_ALL": () => evaluationReducer.initialState,
    RESET_ALL: () => evaluationReducer.initialState,
  },
};

export default evaluationReducer;
