import En from "@image/icones/langue/en.svg";
import Fr from "@image/icones/langue/fr.svg";
import { Evaluation_Format } from "./_constants";
import { convertObjToDropdownCfg } from "./_functions";

export const categories = [
  {
    value: "Vacances",
    title: "Vacances",
    color: "arh-bg-[#609cd3]"
  },
  {
    value: "Absence",
    title: "Absence",
    color: "arh-bg-[#f5d954]"
  },
  {
    value: "Maladie",
    title: "Maladie",
    color: "arh-bg-[#f67c53]"
  }
];

export const status = [
  {
    value: "En attente",
    title: "En attente",
    color: "arh-bg-[#f5d954]"
  },
  {
    value: "Acceptée",
    title: "Acceptée",
    color: "arh-bg-[#92e98f]"
  },
  {
    value: "Refusée",
    title: "Refusée",
    color: "arh-bg-[#f67c53]"
  }
];

export const Languages = [
  {
    _id: "fr",
    language: "Français",
    languageCode: "FR",
    iconeCode: Fr
  },
  {
    _id: "en",
    language: "Anglais",
    languageCode: "EN",
    iconeCode: En
  }
];

export const presetColors = [
  "#FF686B",
  "#FFAE55",
  "#FFE159",
  "#B5FC4E",
  "#72FF99",
  "#7BF1E0",
  "#6FD9FD",
  "#648FFB",
  "#8F5FF9",
  "#DC69FF",
  "#FF70D1",
  "#FCCCD1",
  "#E0E0E0",
  "#9B9B9B",
  "#FFFFFF"
];

export const EVALUATION_FORMAT = convertObjToDropdownCfg(Evaluation_Format);

export const isCustomDomain = () =>
  window.location.origin !== process.env.REACT_APP_URL;

export const SETTABLE_NOTIFICATIONS_ACCESS = [
  {
    key: "new_vacancy_request", //notification msg value
    label: "Demande de vacances pour les employés suivants",
    placeholder: "Notifications de vacances pour les employés suivants"
  },
  {
    key: "new_reimbursment_request", //notification msg value
    label: "Demande de remboursements pour les employés suivants",
    placeholder: "Notifications de remboursements pour les employés suivants"
  }
];
