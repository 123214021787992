import { combineObjectWithoutCloning } from "@helpers/_functions";

export const commonData = {
  initialState: {
    groupes: [],
    emplacements: [],
    employers: [],
    employedTimeclocks: {},
    permissions: [],
    postes: []
  },
  handlers: {
    "SET_GROUPES_LIST": (state, payload) => combineObjectWithoutCloning(state, { groupes: payload }),
    "SET_EMPLACEMENTS_LIST": (state, payload) => combineObjectWithoutCloning(state, { emplacements: payload }),
    "SET_EMPLOYERS_LIST": (state, payload) => combineObjectWithoutCloning(state, { employers: payload }),
    "SET_EMPLOYED_TIMECLOCKS": (state, payload) => combineObjectWithoutCloning(state, { employedTimeclocks: payload }),
    "SET_INACTIVE_EMPLOYERS_LIST": (state, payload) => combineObjectWithoutCloning(state, { inactiveEmployees: payload }),
    "SET_PERMISSIONS_LIST": (state, payload) => combineObjectWithoutCloning(state, { permissions: payload }),
    "SET_POSTES_LIST": (state, payload) => combineObjectWithoutCloning(state, { postes: payload }),
    "RESET_ALL": () => commonData.initialState
  }
};

export default commonData;
