import { combineObjectWithoutCloning } from "@helpers/_functions";

export const userConnectedData = {
  initialState: {
    //employerHour: {},
    canPointing: true,
    permissions: []
  },
  handlers: {
      "WAIT_TO_POINT": (state, payload) => combineObjectWithoutCloning(state, { canPointing: payload }),
      "SET_USER_CONNECTED_INFO": (state, payload = {}) => combineObjectWithoutCloning(state, payload),
      "RESET_ALL": () => userConnectedData.initialState
  }
};

export default userConnectedData;
