import { getResponseData } from "@helpers/_functions";
import http from "../http-common";
import BaseService from "./base.service";

class EmploiCertificationService extends BaseService {
  constructor() {
    super("emploi-certification");
  }
  getByEmployer(id, params = {}) {
    return http.get(`${this.path}/getByEmployer/${id}`, { params });
  }
  getById(id) {
    return http.get(`${this.path}/getById/${id}`);
  }
  insert(data) {
    return http
      .post(`${this.path}/create`, data)
      .then((response) => getResponseData(response));
  }
}

export default new EmploiCertificationService();
