import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LogoAltee from "@image/icones/logoAltee.svg";
import { Button } from "@components/tailwindComponents";
import { parseJsonObject } from "@helpers/_functions";
import trackingFrontErrorService from "@services/management/trackingFrontError.service";
import DisclosureCollapse from "./DisclosureCollapse";

function ErrorFallback({ error, info, isErrorSent, setIsErrorSent }) {
  const { t: translate } = useTranslation("connexion.login");
  
  const refreshApp = () => window.location.reload(true);
  
  const goToDashBoard = () =>
    (window.location.href =
      process.env.REACT_APP_URL + "/admin");

  const saveErrorToLog = useCallback(() => {
    if (!error.message) return;
    const { email, Enterprise, _id: User, username } = parseJsonObject(
      localStorage.getItem("user")
    );
    const { enterpriseName } = parseJsonObject(
      localStorage.getItem("Enterprise")
    );
    const currentTab = localStorage.getItem("current-tab");

    const logInfo = {
      email,
      enterpriseName,
      User,
      Enterprise,
      page: window.location.pathname,
      tab: currentTab,
      errorTitle: error.message,
      info,
      username
    };
    trackingFrontErrorService
      .saveFrontError(logInfo)
      .then((_res) => {
        console.log("Error is sendend to developper");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [error.message, info]);

  useEffect(() => {
    if (!isErrorSent && info) {
      saveErrorToLog();
      setIsErrorSent(true);
    }
  }, [error.message, info]);

  return (
    <div className="arh-bg-white arh-flex arh-justify-center arh-items-center arh-h-screen arh-flex-col arh-space-y-4 arh-font-poppins">
      <div className="arh-flex arh-justify-center arh-items-center arh-flex-col  arh-bg-greyColor arh-w-2/3 arh-h-[600px] mobile-768:arh-h-[250px] mobile-768:arh-w-[95%] mobile-768:arh-p-2">
        <div className="arh-flex arh-justify-center  arh-w-full">
          <img src={LogoAltee} alt="logoAltee" />
        </div>
        <div className="arh-font-semibold arh-text-[#474D55] arh-text-[150px] mobile-768:arh-text-3xl">
          Oops !
        </div>
        <div className="arh-font-normal arh-text-black arh-text-[35px] mobile-768:arh-text-xl">
          Something's missing
        </div>
        <div className="arh-flex arh-items-center arh-space-x-4 mobile-768:arh-mt-2">
          <Button
            label={translate("Recharger la page")}
            onClick={refreshApp}
            modifier="arh-max-w-[161.52px] !arh-w-[161.52px] !arh-h-10 arh-mt-5 arh-bg-[#2C2C31] arh-border-[#2C2C31] hover:arh-border-black hover:arh-bg-black  arh-font-medium arh-text-xs arh-text-white  "
          />
          <Button
            label={translate("Retour à l'accueil")}
            onClick={goToDashBoard}
            modifier="arh-max-w-[161.52px] !arh-w-[161.52px] !arh-h-10 arh-mt-5 arh-bg-white arh-border-white  arh-font-medium arh-text-xs arh-text-[#2C2C31]  "
          />
        </div>  
      </div>
      <div className="arh-flex arh-flex-col arh-space-y-3 arh-text-[#474D55] arh-font-normal arh-text-[13px]">
        <DisclosureCollapse title={translate("Show error")} details={error.message} />
        <DisclosureCollapse title={translate("Show details")} details={info} />
      </div>
    </div>
  );
}

export default ErrorFallback;
