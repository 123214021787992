import moment from "moment";

import {
  checkUserId,
  getResponseData,
  customizeError,
} from "../../helpers/_functions";
import http from "../../http-common";
import BaseService from "../base.service";

class EvaluationModel extends BaseService {
  constructor() {
    super("evaluation-model");
  }
  getAllEmployerByEnterprise(id) {
    id = checkUserId(id);
    return http
      .get(`${this.path}/get-list/${id}`)
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  getEvaluationsOfMonth(evaluationModelId) {
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

    return http
      .get(`${this.path}/get-evaluations-of-month/${evaluationModelId}`, {
        params: { startOfMonth, endOfMonth },
      })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  upsert(evaluationModels) {
    return http
      .post(`${this.path}/upsert`, evaluationModels)
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  reset(evaluationModelId) {
    return http
      .post(`${this.path}/reset/${evaluationModelId}`)
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }
}

export default new EvaluationModel();
