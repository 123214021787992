import React, { useState, lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { GuardProvider } from "react-router-guards";
import "@assets/css/pages/dashboard/global.css";
import "@assets/plugins/nucleo/css/nucleo.css";
import "@assets/scss/argon-dashboard-react.scss";
// import Spinner from "@image/icones/spinner.svg";
// import ResizeHandler from "@Modals/globalMessage/resizeHandler";
import UserContext from "@pages/context/userContext";
import jwt_decode from "jwt-decode";
import { setTokenLocalStorages } from "@pages/connexion/actions/authAction";
// import { useTranslation } from "react-i18next";

require("dotenv").config();

export default function Routes() {
  const AdminLayout = lazy(() => import("@pages/layouts/container"));
  const Connexion = lazy(() => import("@pages/connexion/container/signIn"));
  const ForgotPassword = lazy(
    () => import("@pages/connexion/container/forgotPassword")
  );
  const EmailVerifySuccess = lazy(() => import("@pages/email/verifySuccess"));
  const EmailVerifyFail = lazy(() => import("@pages/email/verifyFail"));
  const ResetPassword = lazy(
    () => import("@pages/connexion/container/resetPassword")
  );
  const CreatePassword = lazy(
    () => import("@pages/connexion/container/createPassword")
  );
  const RegisterEntreprise = lazy(
    () => import("@pages/connexion/container/signUpEntreprise")
  );
  const PageNotFound = lazy(() => import("@pages/errorPage/pageNotFound"));

  const [userData, setUserData] = useState({
    user: undefined,
    id: undefined
  });

  // const { i18n } = useTranslation();

  const isLoggedIn = (Component) => (props) => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      localStorage.setItem("token", token);
      // localStorage.setItem("auth", JSON.stringify(decodedToken));
      setTokenLocalStorages(decodedToken);
      // setTokenLocalStorages(decodedToken, i18n);
      localStorage.setItem("loginUrl", params.get("loginUrl"));
    }
    return localStorage.getItem("token") || token ? (
      <Component {...props} />
    ) : (
      <Redirect to="/se-connecter" />
    );
  };

  const isCustomDomain = (Component) => (props) =>
    window.location.origin === process.env.REACT_APP_URL ? (
      <Component {...props} />
    ) : (
      <Redirect to="/" />
    );

  const CustomUrlConnexion = ({ match }) => (
    <Connexion customURL={match.params.customURL} />
  );

  const CustomUrlForgotPassword = ({ match }) => (
    <ForgotPassword customURL={match.params.customURL} />
  );

  const CustomUrlRedirect = ({ match }) =>
    localStorage.getItem("token") ? (
      <Redirect to={"/admin"} />
    ) : (
      <Redirect to={`/${match.params.customURL}/se-connecter`} />
    );

  return (
    <Suspense
      fallback={
        <div className="loading_spinner d-flex flex-justify-center flex-align-center">
          {/* <img src={Spinner} alt="" /> */}
        </div>
      }
    >
      <BrowserRouter>
        <UserContext.Provider value={{ userData, setUserData }}>
          <GuardProvider>
            <Switch>
              <Route
                path="/admin"
                render={isCustomDomain(isLoggedIn(AdminLayout))}
              />
              <Route
                path="/se-connecter"
                name="signIn"
                render={() => {
                  if (localStorage.getItem("token")) {
                    return <Redirect to="/admin" />;
                  } else {
                    const { hostname, origin } = window.location;
                    return (
                      <Connexion
                        customDomain={
                          origin !== process.env.REACT_APP_URL && hostname
                        }
                      />
                    );
                  }
                }}
              />
              <Route
                path="/:customURL/se-connecter"
                name="signInPrivate"
                render={CustomUrlConnexion}
              />
              <Route
                path="/email/verifysuccess"
                name="emailVerifySuccess"
                component={EmailVerifySuccess}
              />
              <Route
                path="/reinitialisation-mot-de-passe/:token"
                name="resetPassword"
                component={ResetPassword}
              />
              <Route
                path="/creation-mot-de-passe/:token"
                name="createPassword"
                component={CreatePassword}
              />
              <Route
                path="/email/verifyfail"
                name="emailVerifyFail"
                component={EmailVerifyFail}
              />
              <Route
                path="/mot-de-passe-oublié"
                name="forgotPassword"
                component={ForgotPassword}
              />

              <Route
                path="/:customURL/mot-de-passe-oublié"
                name="forgotPasswordPrivate"
                render={CustomUrlForgotPassword}
              />

              <Route
                path="/inscription-entreprise"
                name="RegisterEntreprise"
                component={isCustomDomain(RegisterEntreprise)}
              />
              <Route
                exact
                path="/"
                render={(props) => {
                  const { hostname, origin } = window.location;
                  if (origin !== process.env.REACT_APP_URL)
                    return <Connexion customDomain={hostname} />;
                  if (localStorage.getItem("token")) {
                    return <Redirect to="/admin" />;
                  }
                  return <Redirect to="/se-connecter" />;
                }}
              />
              <Route
                exact
                path="/:customURL/"
                render={CustomUrlRedirect}
              />
              <Route component={PageNotFound} />
            </Switch>
          </GuardProvider>
        </UserContext.Provider>
        {/* modal qui bloque la version mobile <ResizeHandler /> */}
      </BrowserRouter>
    </Suspense>
  );
}
