import { getResponseQuery } from "../../helpers/_functions";
import http from "../../http-common";
import BaseService from "../base.service";

class EvaluationRequest extends BaseService {
  constructor() {
    super("evaluation-requests");
  }

  getByEnterprise(params = {}) {
    const enterpriseId = localStorage.getItem("enterpriseId");

    return getResponseQuery(
      http.get(`${this.path}/get-by-enterprise/${enterpriseId}`, {
        params,
      }),
    );
  }

  getLastEvaluationRequests(assignedEmployee, evaluationType) {
    return getResponseQuery(
      http.get(
        `${this.path}/get-last-evaluation-requests/${assignedEmployee}/${evaluationType}`,
      ),
    );
  }

  getDevelopmentPlanList(userId) {
    return getResponseQuery(
      http.get(`${this.path}/get-development-plan-list/${userId}`),
    );
  }
}

export default new EvaluationRequest();
