import React, { useState, useRef } from "react";

import BlackTooltip from "@components/tooltip/BlackTooltip";

import { BLANK_PROFILE_PICTURE } from "@helpers/_constants";

/**
 * @typedef {object} Props
 * @property {string=} src - URL de l'image à afficher
 * @property {string=} name - Nom de l'employé
 * @property {boolean=} showName - Par defaut true
 * - si false, le nom de l'employé n'est pas affiché à côté de l'avatar
 * @property {string=} textStyle
 * - Class pour personnalisé l'élément contenant l'avatar
 * - Uniquement si showName = true
 * @property {string=} widthAvatar - Class definissant la largeur de l'avatar
 * @property {string=} heightAvatar - Class definissant la hauteur de l'avatar
 * @property {React.MutableRefObject<HTMLElement>=} headRef - Spécifique à liste employé
 * @property {string=} parentAvatar - Custom class à mettre sur l'élément parent de l'Avatar
 * @property {boolean=} IsOverflowHIdden - Par defaut false
 * - Si true, l'élément parent de l'Avatar est en overflow hidden
 * - Ref de l'entête du tableau
 */

/**
 *
 * @param {Props} props
 * @returns
 */
function Avatar(props) {
  const {
    src,
    name,
    textStyle = "",
    widthAvatar = "arh-w-10 mobile-768:arh-w-8",
    heightAvatar = "arh-h-10 mobile-768:arh-h-8",
    showName = true,
    headRef,
    parentAvatar,
    IsOverflowHIdden = false,
  } = props;

  /** @type {React.MutableRefObject<HTMLElement>} */
  const tooltipRef = useRef();

  /** @type [any, Function] */
  const [position, setPosition] = useState("top-start");

  function onOpen() {
    if (headRef?.current && tooltipRef.current) {
      const { y: topHeadTable } = headRef.current.getBoundingClientRect();
      const { y: topTooltip } = tooltipRef.current.getBoundingClientRect();
      if (topTooltip - topHeadTable < 60) {
        if (position !== "bottom-start") setPosition("bottom-start");
      } else {
        if (position !== "top-start") setPosition("top-start");
      }
    }
  }

  return (
    <BlackTooltip
      title={name}
      placement={position}
      onOpen={onOpen}
      ref={tooltipRef}
    >
      <div
        className={`arh-avatar-mobile arh-flex arh-items-center arh-space-x-2.5 arh-max-w-fit ${parentAvatar} ${IsOverflowHIdden ? "arh-overflow-hidden" : ""}`}
      >
        <div
          className={`arh-shrink-0 ${widthAvatar} ${heightAvatar} arh-overflow-hidden arh-rounded-full arh-border arh-border-solid arh-border-white`}
        >
          <img
            src={src || BLANK_PROFILE_PICTURE}
            alt="avatar"
            className="arh-h-full arh-w-full arh-object-cover"
          />
        </div>
        {showName && (
          <p
            className={`arh-mb-0 arh-shrink arh-truncate arh-font-poppins arh-text-xs arh-font-medium arh-text-black ${textStyle}`}
          >
            {name}
          </p>
        )}
      </div>
    </BlackTooltip>
  );
}

export default Avatar;
