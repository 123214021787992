import { customizeError, getResponseData } from "@helpers/_functions";
import http from "../../http-common";

export default class BaseDocumentationService {
  constructor(path) {
    this.path = path;
  }

  insert(data) {
    return http
      .post(`${this.path}/`, data)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getByEnterprise(enterpriseId, queryParams = {}) {
    const { userId } = queryParams;
    const query = userId ? `?employer=${userId}` : "";
    return http
      .get(`${this.path}/enterprise/${enterpriseId}${query}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  updateById(item) {
    return http.put(`${this.path}/${item._id}`, item);
  }

  deleteById(id, queryParams = null) {
    return http
      .delete(`${this.path}/${id}${queryParams ? queryParams : ""}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  handle(data) {
    return http
      .post(`${this.path}/handle`, data)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getStats(documentId) {
    const userId = localStorage.getItem("UserAdmin");
    const query = `?userId=${userId}&documentation=${documentId}`;
    return http
      .get(`${this.path}/stats${query}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  moveFolder(data) {
    return http
      .post(`${this.path}/move`, data)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }
}
