import BaseService from "./base.service";
import http from "../http-common";

class DataImageService extends BaseService {
  constructor() {
    super("data-image");
  }

  deleteImage({ sourceModel, source }) {
    return http.get(`${this.path}/deleteImage/${sourceModel}/${source}`);
  }
  updateDataImageById(id, data) {
    return http.put(`${this.path}/update/${id}`, data);
  }
  findBySource({ sourceModel, source }) {
    return http.get(`${this.path}/findBySource/${sourceModel}/${source}`);
  }
}

export default new DataImageService();
