import {
  checkEnterpriseId,
  customizeError,
  getResponseData,
} from "@helpers/_functions";

import http from "../../http-common";
import BaseService from "../base.service";

class EvaluationResult extends BaseService {
  constructor() {
    super("evaluation-results");
  }

  getResumeCompOrPerf(enterpriseId, params = {}) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    return http
      .get(`${this.path}/resume/${enterpriseId}`, {
        params: { ...params, userId: localStorage.getItem("userId") },
      })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  getEvaluationResultDetails({ criteriaId, employeId }, params = {}) {
    return http
      .get(
        `${this.path}/get-evaluation-result-details/${employeId}/${criteriaId}`,
      )
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  getNotationGraphData({ criteriaId, employeId }, params = {}) {
    return http
      .get(`${this.path}/get-notation-graph-data/${employeId}/${criteriaId}`, {
        params,
      })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  getNotations({ employeId, criteriaId }, params = {}) {
    return http
      .get(`${this.path}/details/notations/${employeId}/${criteriaId}`, {
        params,
      })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  getQuestionAnswers({ employeId, questionId, ...rest }) {
    return http
      .get(`${this.path}/question-answers/${employeId}/${questionId}`, {
        params: rest,
      })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  getByEvaluationRequest(evaluationRequestId) {
    return http
      .get(`${this.path}/get-by-evaluation-request/${evaluationRequestId}/`)
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  getLastEvaluationResult({ criteriaId, employeId }) {
    return http
      .get(`${this.path}/last-evaluation-result/${employeId}/${criteriaId}`)
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  getByEmployee(assignedEmployee, evaluationType) {
    return http
      .get(`${this.path}/get-by-employee/${assignedEmployee}/${evaluationType}`)
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  getHistory(enterpriseId, params) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    return http
      .get(`${this.path}/get-history/${enterpriseId}`, { params })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  deleteMany(ids = []) {
    return http
      .delete(`${this.path}/delete-many/${ids.join("-")}`)
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }

  /**
   *
   * @param {Object} reqBody
   * @param {string} reqBody.startDate
   * @param {string} reqBody.endDate
   * @param {string} reqBody.assignedEmployee
   * @param {string} reqBody.evaluationModelId
   * @param {string} reqBody.evaluationType
   * @returns
   */
  checkRedundancy(reqBody) {
    return http
      .post(`${this.path}/check-redundancy`, reqBody)
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }
}

export default new EvaluationResult();
