import { getResponseData, customizeError } from "../helpers/_functions";
import http from "../http-common";
import BaseService from "./base.service";

class MaterialService extends BaseService {
  constructor() {
    super("material");
  }

  insert(data) {
    return super
      .insert(data)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  updateById(item) {
    return super
      .updateById(item)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  deleteById(item) {
    return super
      .deleteById(item)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getListFiltered(id, params = {}) {
    // filters = JSON.stringify(filters);
    return http
      .get(`${this.path}/listFiltered/${id}`, { params })
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getListByEnterprise(id, filters = {}) {
    const userId = localStorage.getItem("userId");

    return http
      .get(`${this.path}/listFiltered/${id}`, {
        params: { ...filters, userId },
      })
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getMaterialField(params) {
    return http
      .get(`${this.path}/field`, { params })
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getListByEmployer(id, filters = {}) {
    // filters = JSON.stringify(filters);
    return http
      .get(`${this.path}/listFiltered/${id}`, { params: filters })
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getListCategoryByEnterprise(id) {
    return http
      .get(`${this.path}/category/list/${id}`)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }
  checkMaterial(id, filters = {}) {
    return http
      .get(`${this.path}/check/${id}`, { params: filters })
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getMaterialLog(materialId = null) {
    const enterpriseId = localStorage.getItem("enterpriseId");
    return http
      .get(`${this.path}/log/${enterpriseId}/${materialId}.log`)
      .then((res) => res?.data)
      .catch((err) => Promise.reject(customizeError(err)));
  }

  checkDoublonBeforeImport(data) {
    return http
      .post(`${this.path}/import/check-doublon`, data)
      .then((response) => response.data.data)
      .catch((err) => Promise.reject(customizeError(err)));
  }

  saveImportedMaterials(data) {
    return http
      .post(`${this.path}/import/save`, data)
      .then((response) => response.data.data)
      .catch((err) => Promise.reject(customizeError(err)));
  }

  exportResultMaterial(data) {
    return http
      .post(`${this.path}/import/export-result`, data)
      .then((response) => response.data.data)
      .catch((err) => Promise.reject(customizeError(err)));
  }

  downloadResult(theFileName) {
    return http.get(`${this.path}/import/download-result/${theFileName}`, {
      responseType: "blob",
    });
  }

  downloadGabarit() {
    return http.get(`${this.path}/import/gabarit`, {
      responseType: "blob",
    });
  }

  checkIfAlreadyInDb(material) {
    return http.post(`${this.path}/import/check-if-in-db`, material);
  }

  getInvalidUniqueFields(material) {
    return http.post(`${this.path}/import/get-invalid-unique-fields`, material);
  }

  getStatistics(filters = {}) {
    const enterpriseId = localStorage.getItem("enterpriseId");
    const userId = localStorage.getItem("userId");
    filters = JSON.stringify(filters);

    return http
      .get(`${this.path}/statistics/${enterpriseId}`, {
        params: { filters, userId },
      })
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }
}

export default new MaterialService();
