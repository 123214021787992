import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";

import { Listbox, Transition } from "@headlessui/react";
import { Caret } from "@routes/IconeSvg";

import CheckboxSimple from "./CheckboxSimple";

/**
 * @typedef {Object} Props
 * @property {string=} placeholder
 * @property {string=} name
 * @property {any} selectedValue
 * @property {(value: any) => void} setSelectedValue
 * @property {Array<any>} optionList
 * @property {string=} optionValueAttibut Par defaut _id
 * @property {string=} optionTextAttribut Par defaut name
 * @property {boolean=} invalid
 * @property {boolean=} readOnly
 * @property {boolean=} multiple
 * @property {import("react-i18next").TFunction<"translation", any>=} customTranslator
 * @property {string=} customListHeight
 * @property {string=} anotherParentClass
 * @property {string=} classParentList
 * @property {string=} classLabel
 * @property {string=} label
 * @property {boolean=} topView
 */

/**
 *
 * @param {Props} props
 * @returns
 */
function BaseListBox(props) {
  const {
    // requiredValues
    placeholder,
    name,
    selectedValue,
    setSelectedValue,
    optionList = [],

    // default options
    optionValueAttibut = "_id",
    optionTextAttribut = "name",
    invalid = false,
    readOnly = false,
    multiple = false,
    customTranslator = null,
    customListHeight = "arh-max-h-60",
    anotherParentClass,
    topView,
  } = props;
  const buttonRef = useRef(null);

  // Helpers
  const translateAndShow = useCallback(
    (value) => {
      return typeof customTranslator === "function"
        ? customTranslator(value)
        : value;
    },
    [customTranslator],
  );

  // Selection Handling
  const [localSelectedValue, setLocalSelectedValue] = useState([]);

  const isOptionSelected = useCallback(
    (optionValue) => {
      return localSelectedValue.some(
        (ls) => ls[optionValueAttibut] === optionValue,
      );
    },
    [localSelectedValue],
  );

  useEffect(() => {
    if (Array.isArray(selectedValue)) {
      setLocalSelectedValue(selectedValue);
    }
  }, [selectedValue]);

  function onAddClicked() {
    setSelectedValue(localSelectedValue);
    buttonRef.current?.click();
  }

  const onSelectedChange = useCallback(
    (value) => {
      setLocalSelectedValue(removeDuplication(value, optionValueAttibut));
    },
    [optionValueAttibut, multiple],
  );

  return (
    <Listbox
      value={localSelectedValue}
      onChange={onSelectedChange}
      name={name}
      multiple
    >
      {({ open }) => (
        <div
          className={
            props.classParentList + " arh-relative arh-mb-[0.938rem] arh-w-full"
          }
        >
          {props.label ? (
            <label
              className={`
                arh-mb-1 arh-flex arh-w-full arh-font-poppins arh-text-xs arh-font-medium 
                ${props?.classLabel} 
                ${invalid ? " arh-text-[#F60A20] " : " arh-text-black "} 
              `}
            >
              {props?.label}
            </label>
          ) : (
            ""
          )}
          <Listbox.Button
            className={`arh- arh-relative arh-cursor-pointer arh-h-11 arh-w-full  arh-rounded arh-border arh-border-solid arh-py-3 arh-pl-6 arh-pr-8 arh-text-left arh-font-poppins arh-text-xs arh-font-normal focus:arh-outline-none focus-visible:arh-border-[#ECECEC] focus-visible:arh-ring-2 focus-visible:arh-ring-[#ECECEC] focus-visible:arh-ring-opacity-75 focus-visible:arh-ring-offset-2 focus-visible:arh-ring-offset-greyColor ${
              !invalid
                ? " arh-border-[#ECECEC] arh-bg-white placeholder:arh-text-[#8B8F91] "
                : " arh-border-[#F60A20] arh-bg-[#FFF3F4] arh-text-[#F60A20] placeholder:arh-text-[#F60A20]"
            } ${readOnly && "arh-bg-[#e9ecef]"}`}
            as="div"
            ref={buttonRef}
          >
            <input
              className={`arh-pointer-events-none arh-block arh-w-full arh-max-w-full arh-truncate arh-text-ellipsis arh-border-0 arh-text-darkColor placeholder:arh-text-[#8B8F91] ${
                readOnly && "arh-bg-[#e9ecef]"
              } ${invalid && "arh-text-[#F60A20]"}`}
              type="text"
              placeholder={translateAndShow(placeholder)}
              value={""}
            />
            <span className="arh-pointer-events-none arh-absolute arh-inset-y-0 arh-right-0 arh-flex arh-items-center arh-pr-18">
              <Caret
                className={`${open ? "arh-rotate-90" : "arh-rotate-0"}`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="arh-transition arh-ease-in arh-duration-100"
            leaveFrom="arh-opacity-100"
            leaveTo="arh-opacity-0"
          >
            <Listbox.Options
              className={`${customListHeight}
                arh-absolute arh-z-[6]  arh-mt-1 arh-w-full  arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-bg-white arh-px-4 arh-py-6 arh-text-xs arh-shadow-xs 3xl:arh-w-[120%] desktop-l:arh-w-[100%]
                ${anotherParentClass} ${
                  customListHeight !== "arh-max-h-60" && "arh-overflow-y-auto"
                }
                ${topView && "arh-bottom-full arh-mb-1"}
                `}
            >
              {/* {withSearch && (
                <div className={`${employeeList ? "arh-mb-4" : "arh-mb-5"}`}>
                  <SearchInput
                    placeholder="Rechercher"
                    value={searchValue}
                    onChange={setSearchValue}
                  />
                </div>
              )} */}

              {optionList.map((option) => (
                <Listbox.Option
                  key={option[optionValueAttibut]}
                  className={`arh-relative arh-cursor-pointer arh-select-none arh-list-none  arh-truncate arh-py-1.5 arh-font-poppins arh-text-xs arh-font-normal arh-text-darkColor`}
                  value={option}
                >
                  <CheckboxSimple
                    label={translateAndShow(option[optionTextAttribut])}
                    checked={isOptionSelected(option[optionValueAttibut])}
                    // color={props.color}
                  />
                </Listbox.Option>
              ))}
              <button
                className="altee_btn altee_btn--green medium w-100 padding_add_etiquette"
                onClick={onAddClicked}
              >
                Ajouter
              </button>
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

const findDuplication = (arr) =>
  arr.filter((item, index) => arr.indexOf(item) != index);

function removeDuplication(list, valueAttribut) {
  const duplications = findDuplication(list.map((item) => item[valueAttribut]));
  return list.filter((item) => !duplications.includes(item[valueAttribut]));
}

export default React.memo(BaseListBox);
