import { makeActionHandler } from "./_reducerUtils";
import moment from "moment-timezone";

const formationReducer = {
  initialState: {
    formation: {
      list: [],
      filter:{
        period: {
          startDate: moment().subtract(3, "month").toDate(),
          endDate: moment().add(3, "month").toDate(),
        }
      },
      isLoaded: false
    },
    category: [],
    trainer: [],
    elligibility: [],
    options: {
      list: [],
      isLoaded: false
    },
  },
  handlers: {
    "formation/SET_LIST": makeActionHandler("formation"),
    "formation/SET_CATEGORY": makeActionHandler("category"),
    "formation/SET_TRAINER": makeActionHandler("trainer"),
    "formation/SET_ELLIGIBILITY": makeActionHandler("elligibility"),
    "formation/SET_OPTIONS": makeActionHandler("options"),
    "RESET_ALL": () => formationReducer.initialState
  }
};

export default formationReducer;
