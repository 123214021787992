import BaseService from "../base.service";
import http from "../../http-common";
import { getResponseQuery } from "../../helpers/_functions";

class EvaluationObjective extends BaseService {
  constructor() {
    super("evaluation-objectives");
  }

  createMany(reqBody) {
    return getResponseQuery(
      http.post(`${this.path}/create-many`, reqBody)
    )
  }

  /**
   * 
   * @param {string} assignedEmployee 
   * @param {string} enterpriseId 
   * @returns 
   */
  getByEmployee(assignedEmployee, enterpriseId) {
    return getResponseQuery(
      http.get(`${this.path}/get-by-employee/${assignedEmployee}/${enterpriseId}`)
    )
  }
}

export default new EvaluationObjective();