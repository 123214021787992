import React from "react";

import { BLANK_PROFILE_PICTURE } from "@helpers/_constants";

import Avatar from "./Avatar";
import BaseListBox from "./BaseListBox";
import CheckboxSimple from "./CheckboxSimple";

/**
 * @typedef {Object} Props
 * @property {import("./CheckboxSimple").CheckBoxSimpleVariant=} variant
 */

/**
 *
 * @param {(import("./BaseListBox").BaseListBoxProps & Props)} props
 * @returns
 */
export default function ListboxEmploye(props) {
  const { variant, ...baselistboxProps } = props;

  return (
    <BaseListBox
      {...baselistboxProps}
      optionValueAttibut="id"
      optionTextAttribut="fullName"
      customListHeight="arh-max-h-[32rem]"
      anotherParentClass="arh-min-w-64"
      withSearch
    >
      {({ option: item, selected, noRadio }) => (
        <CheckboxSimple
          id={item.id}
          checked={selected}
          noRadio={Boolean(noRadio)}
          label={
            <Avatar
              src={item.imgEmploye || BLANK_PROFILE_PICTURE}
              name={item?.fullName}
              textStyle="arh-font-poppins arh-font-normal arh-text-xs arh-text-black"
            />
          }
          variant={variant}
        />
      )}
    </BaseListBox>
  );
}
