import React, { useMemo } from "react";

import { useReduxSelector } from "@hooks/useReduxSelector";
import { Controller } from "react-hook-form";

import { mapEmplacementToDevise } from "@helpers/_functions";

import ListboxSimple from "./ListboxSimple";

/**
 * @typedef {Object} Props
 * @property {string=} name
 * @property {import("react-hook-form").Control<any>=} control
 * @property {any=} defaultValue
 * @property {boolean=} disable
 * @property {Omit<import("react-hook-form").RegisterOptions<any, string>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">=} rules
 * @property {string=} customButtonClass
 */

/**
 *
 * @param {Props} props
 * @returns
 */
export default function DropdownDevise(props) {
  const {
    name = "devise",
    control,
    defaultValue = {},
    disable = false,
    rules = {},
    customButtonClass = "!arh-border-0 !arh-bg-transparent !arh-py-0",
  } = props;

  const emplacementsList = useReduxSelector(
    ({ commonData }) => commonData.emplacements,
  );
  const deviseList = useMemo(
    () => mapEmplacementToDevise(emplacementsList),
    [emplacementsList],
  );
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue?._id ? defaultValue : deviseList[0]}
      render={({ field: { onChange, value, name } }) => (
        <ListboxSimple
          name={name}
          optionList={deviseList || []}
          selectedValue={value}
          setSelectedValue={onChange}
          noRadio={true}
          customButtonClass={customButtonClass}
          widthDrop="!arh-w-full arh-px-0 arh-mt-2.5"
          customClass="arh-ml-0"
          className="arh-justify-center"
          readOnly={disable}
          customItemWidth="arh-min-w-24"
        />
      )}
    />
  );
}
